.safenetwork-featurette {
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border-top: 1px solid #eee;

  .highlight {
    text-align: left;
  }

  .lead {
    margin-right: auto;
    margin-bottom: 2rem;
    margin-left: auto;
    font-size: 1rem;
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    .col-sm-6:first-child {
      padding-right: ($grid-gutter-width * 1.5);
    };
    .col-sm-6:last-child {
      padding-left: ($grid-gutter-width * 1.5);
    }
  }
}

.safenetwork-featurette-title {
  margin-bottom: .5rem;
  font-size: 1.75rem;
  font-weight: normal;
  color: #333;
  text-align: center;
}

.half-rule {
  width: 6rem;
  margin: 2.5rem auto;

  @include media-breakpoint-up(sm) {
    margin-right: 0;
    margin-left: 0;
  }
}
.safenetwork-featurette h4 {
  margin-top: 1rem;
  margin-bottom: .5rem;
  font-weight: normal;
  color: #333;
}
.safenetwork-featurette-img {
  display: block;
  margin-bottom: 1.25rem;
  color: #333;
}
.safenetwork-featurette-img:hover {
  color: $primary;
  text-decoration: none;
}
.safenetwork-featurette-img img {
  display: block;
  margin-bottom: 1rem;
}

@media (min-width: 480px) {
  .safenetwork-featurette .img-fluid {
    margin-top: 2rem;
  }
}
@media (min-width: 768px) {
  .safenetwork-featurette {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .safenetwork-featurette-title {
    font-size: 2.5rem;

    + .lead {
      font-size: 1.5rem;
    }
  }
  .safenetwork-featurette .lead {
    max-width: 80%;
  }
  .safenetwork-featurette .img-fluid {
    margin-top: 0;
  }
}
