.safenetwork-masthead {
  position: relative;
  padding: 3rem ($grid-gutter-width / 2) 2rem;
  color: $safenetwork-blue-light;
  text-align: center;
  background-image: linear-gradient(135deg, darken($safenetwork-blue, 20%), $safenetwork-blue, lighten(saturate($safenetwork-blue, 5%), 15%));

  h1 {
    font-weight: 300;
    line-height: 1;
  }

  .lead {
    margin-right: auto;
    margin-bottom: 2rem;
    margin-left: auto;
    font-size: 1.25rem;
    color: #fff;
  }

  .version {
    margin-top: -1rem;
    margin-bottom: 2rem;
  }

  .btn {
    padding: 1rem 2rem;
    font-size: 1.4rem;
    color: #fff;
    border-color: #fff;

    &:hover {
      color: #000;
      background-color: #fff;
      border-color: #fff;
    }
  }

  @include media-breakpoint-up(sm) {
    padding-top: 4rem;
    padding-bottom: 2rem;

    .btn {
      width: auto;
    }
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 3rem;

    .safenetwork-header {
      margin-bottom: 4rem;
    }

    h1 {
      font-size: 4rem;
    }

    .lead {
      font-size: 1.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .lead {
      width: 85%;
      font-size: 2rem;
    }
  }
}
